import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import PlainHeader from "components/ui/base/PlainHeader";
import BentoBoxCustomerServiceSolutions from "components/ui/extended/BentoBoxCustomerServiceSolutions";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import CustomerSupportFeatures from "components/ui/extended/CustomerSupportFeatures";

interface CustomerServicePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: CustomerServicePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.customer-service-solution.seo.title")}
        description={t("pages.customer-service-solution.seo.description")}
        image={{
          relativePath: "meta/customer-service-software.jpg",
          alt: "Customer support Software to make your customer life easier",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.customer-service-solution.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.customer-service-solution.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.customer-service-solution.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.customer-service-solution.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.customer-service-solution.title")}
        description={t("pages.customer-service-solution.description")}
        titleMaxWidth={1260}
        descriptionMaxWidth={900}
        ctaRegister={{ show: true }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <BentoBoxCustomerServiceSolutions />

      <Customers />

      <CustomerSupportFeatures />

      <TestimonialWithImageFrancesca />

      <RegisterForCustomerService />

      <BlogPostsBox
        category={"customer-service"}
        title={"Customer Support Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"live-chat"}
      />
    </GlobalWrapper>
  );
};
export const CustomerServiceQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customer-service-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
